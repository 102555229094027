import React from 'react';

interface SupportProps {}

const Support: React.FC<SupportProps> = () => {
    return (
        <div className="container mt-5">
            <div className="jumbotron">
                <h1 className="display-5">Need Help?</h1>
                <p className="lead">Our support team is here to help you. Please email us at <a href="mailto:support@lexihive.com" className="alert-link">support@lexihive.com</a>.</p>
                <hr className="my-4" />
                <p>We strive to respond to all inquiries as soon as possible.</p>
            </div>
        </div>
    );
};

export default Support;

import { useState, useEffect } from "react";
import useLetters from "./use-letters";

export interface AppSettings {
  letters: string;
  lettersLoading: boolean;
  hivemode: "prefix" | "filter";
  showNChars: number;
}

const useAppSettings = (): [AppSettings, React.Dispatch<React.SetStateAction<AppSettings>>] => {
  const [letters, setLetters, lettersLoading] = useLetters();

  // Retrieve showNChars from localStorage if it exists, otherwise use default value (2)
  const storedShowNChars = localStorage.getItem("showNChars");
  const initialShowNChars = storedShowNChars ? parseInt(storedShowNChars, 10) : 2;

  const [settings, setSettings] = useState<AppSettings>({
    letters: letters,
    lettersLoading: lettersLoading,
    hivemode: "filter",
    showNChars: initialShowNChars,
  });

  useEffect(() => {
    setSettings(prevSettings => ({ ...prevSettings, letters: letters, lettersLoading:lettersLoading }));
  }, [letters, lettersLoading]);

  // Save showNChars to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("showNChars", settings.showNChars.toString());
  }, [settings.showNChars]);

  const updateSettings = (newSettings: React.SetStateAction<AppSettings>) => {
    setSettings(prevSettings => {
      const updatedSettings = typeof newSettings === "function" ? newSettings(prevSettings) : newSettings;
      setLetters(updatedSettings.letters);
      return updatedSettings;
    });
  };

  return [settings, updateSettings];
}

export default useAppSettings;

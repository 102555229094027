import "./div-input.scss";

import Cursor from "./cursor";
import { Button } from "react-bootstrap";
import { XCircle } from "react-bootstrap-icons";

interface DivInputProps {
  hasFocus: boolean;
  value: string;
  title: string;
  xclicked: () => void;
}

const DivInput: React.FC<DivInputProps> = ({
  hasFocus,
  title,
  value,
  xclicked,
}: DivInputProps) => {
  return (
    <div className="d-flex align-items-center mt-1">
      <span className="me-2">{title}:</span>
      <div
        tabIndex={0}
        className={`d-flex flex-grow-1 justify-content-center align-items-center divinput border border-warning rounded posrel ${
          hasFocus ? "focusshadow" : ""
        }`}
      >
        <span tabIndex={-1} className="h2 text-uppercase mb-1">
          {value}
        </span>
        <Cursor hasFocus={hasFocus}></Cursor>
        <Button
          variant="light"
          className="posright me-2 p-0"
          onClick={() => {
            xclicked();
          }}
        >
          <XCircle className="mb-1" size={"1em"}></XCircle>
        </Button>
      </div>
    </div>
  );
};

export default DivInput;

import "./words.scss"

import React, { useMemo } from "react"

import Word from "./word"
import WordElem from "./word-elem"

export interface WordsComponentProps {
  words: Word[];
  letters: string;
  obscurityLimit: number;
  nchars?: number;
}

interface DisplayWord extends Word {
  obscurity?: number;
}

const Words: React.FC<WordsComponentProps> = (props: WordsComponentProps) => {
  // Create an array of Word arrays
  // Where each "row" has words starting with the same letter
  // Within a row, words are sorted from shortest to longest (then alphabetically)
  let letters = useMemo(() => props.letters.split("").sort(), [props.letters]);
  let words = useMemo(() => [...props.words] as DisplayWord[], [props.words]);

  // Sort by obscurity, putting the least obscure words at the beginning.
  // Then assign obscurity values from least obscure (0) to most obscure (<=100)
  words.sort((wordA, wordB) => {
    let [a, b] = [wordA.ngrams?.matchcount ?? 0, wordB.ngrams?.matchcount ?? 0];
    return a > b ? -1 : a < b ? 1 : 0;
  });
  for (let i = 0; i < words.length; i++) {
    words[i].obscurity = (i / words.length) * 100;
  }

  const filteredWords = letters
    .map((letter) => {
      let subset = words.filter((v) => {
        return (
          v.word.charAt(0) === letter && v.obscurity! <= props.obscurityLimit
        );
      });
      subset?.sort((a, b) => {
        return a.word.length < b.word.length
          ? -1
          : a.word.localeCompare(b.word);
      });
      return subset ?? [];
    })
    .filter((v) => {
      return v.length > 0;
    });

  const ispangram = (letters: string, word: string) => {
    const re = new RegExp(`^[${word}]+$`);
    return re.test(letters);
  };

  return (
    <div className="my-2">
      {filteredWords.map((row, i) => {
        return (
          <div key={i} className="grid-container">
            {row.map((v, i) => {
              return (
                <WordElem
                  key={i}
                  word={v}
                  ispangram={ispangram(props.letters, v.word)}
                  nchars={props.nchars}
                ></WordElem>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Words;

import { Form } from "react-bootstrap";
import "./vrange.scss";

export interface VerticalRangeProps {
  initialValue: number;
  onChange: (value: React.SetStateAction<number>) => void;
}

const VerticalRange = ({ initialValue, onChange }: VerticalRangeProps) => {
  return (
    <div className="vertical-container">
      <span className="rangetitle">Obscurity</span>
      <div className="vertical-range" title="Obscurity Filter">
        <Form.Range
          value={initialValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            let p: number = +event.currentTarget.value;
            onChange(p);
          }}
        />
      </div>
    </div>
  );
};

export default VerticalRange;

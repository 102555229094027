import { useEffect } from "react";

import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./login.scss"

import { useLocation, useNavigate } from "react-router";

function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";
  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  const emailFields = {
    username: {
      placeholder: "Enter your Email",
      isRequired: true,
      label: "Email",
    },
  };
  const formFields = {
    signIn: {...emailFields},
    signUp: {...emailFields},
    resetPassword: {...emailFields}
  };

  return (
    <View className="m-2">
      <Authenticator
        socialProviders={["google"]}
        formFields={formFields}
      ></Authenticator>
    </View>
  );
}

export default Login;

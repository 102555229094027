import "./index.css";

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";

import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import App from "./App";
import { Config as AppConfig } from "./app-config";
import Login from "./auth/login";
import ErrorPage from "./error";
import Home from "./home";
import Checkout from "./payment/checkout";
import Sponsor from "./payment/sponsor";
import ThankYou from "./payment/thankyou";
import reportWebVitals from "./reportWebVitals";
import Support from "./support";

Amplify.configure(AppConfig.amplify);

const titles: Record<string, string> = {
  "/": "LexiHive",
  "/login": "LexiHive - Login",
  "/sponsorship": "LexiHive - Sponsorship",
  "/sponsorship/checkout": "LexiHive - Checkout",
  "/sponsorship/thankyou": "LexiHive - Thank You",
};

const AuthApp = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = titles[location.pathname] ?? "LexiHive";
  }, [location]);
  return (
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthApp />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/sponsorship",
        children: [
          {
            index: true,
            element: <Sponsor />,
          },
          {
            path: "checkout",
            element: <Checkout />,
          },
          {
            path: "thankyou",
            element: <ThankYou />,
          },
        ],
      },
      {
        path: "/support",
        element: <Support />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

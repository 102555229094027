import { API } from 'aws-amplify'
import { useCallback, useEffect, useState } from 'react'

interface ScrapedLetters {
  centerletter: string
  otherletters: string
}

const API_NAME = "letters"

const useLetters = (): [string, (newLetters: string) => void, boolean] => {
  const [letters, setLetters] = useState<string>("abcdefg")
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const storageKey = 'lettersData'
  const today = new Date().toDateString()

  // Set the letters and track in local storage
  const updateLetters = useCallback<(newLetters: string) => void>((newLetters) => {
    // Users of this hook assume the letters are lowercase.
    newLetters = newLetters.toLowerCase()
    setLetters(newLetters)

    localStorage.setItem(
      storageKey,
      JSON.stringify({
        date: today,
        letters: newLetters,
      })
    )
  }, [today])

  const fetchLetters = useCallback<() => Promise<string>>(() => {
    setIsLoading(true)
    const storedData = localStorage.getItem(storageKey)

    if (storedData) {
      const parsedData = JSON.parse(storedData)

      if (parsedData.date === today) {
        return Promise.resolve<string>(parsedData.letters)
      }
    }

    return API.get(API_NAME, "", {})
      .then((response) => {
        const letters = response as ScrapedLetters
        return letters.centerletter + letters.otherletters
      })
  }, [today])

  // Get the Id token
  useEffect(() => {

    fetchLetters()
      .then((letters) => { 
        updateLetters(letters) 
        setIsLoading(false)
      })
      .catch((error) => { 
        console.error("Failed to fetch letters:", error)
        setIsLoading(false) 
      })
  }, [today, fetchLetters, updateLetters])

  return [letters, updateLetters, isLoading]
}

export default useLetters

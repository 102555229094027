import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import "./checkout.scss";
import { Product } from "./product";
import ProductComponent from "./productcomponent";
import StripeComponent from "./stripe";

export default function Checkout() {
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [product, setProduct] = useState<Product | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const params = new URLSearchParams(window.location.search);
  const amountparam = Number(params.get("amount"));
  const type = params.get("type");
  if (
    amountparam < 100 ||
    amountparam % 100 ||
    !(type === "onetime" || type === "recurring")
  ) {
    throw new Response(
      `Invalid sponsorship (amount:${(amountparam / 100).toFixed(
        2
      )}, type:${type}).`,
      { status: 400, statusText: "Bad Request" }
    );
  }

  const [amount, setAmount] = useState<number>(amountparam);

  useEffect(() => {
    if (!product && !loadingProduct) {
      setLoadingProduct(true);
      setProduct({
        id: "",
        name: "One-Time Sponsorship of LexiHive.com",
        description: "We like keeping our site ad-free and bee-autiful.  Your sponsorships make this possible.",
        price: amount,
        type: type,
      });
      setLoadingProduct(false);
    }
  }, [product, setProduct, loadingProduct, setLoadingProduct, amount, type]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!product && loadingProduct) {
    return (
      <div className="m-2">
        <span>Loading</span> <Spinner variant="warning" />
      </div>
    );
  }

  const onAmountChange = (newAmount:number) => {
    const minAmount = 50; // 50 cents
    const maxAmount = 1000000 // $10K
    if ((newAmount >= minAmount) && (newAmount <= maxAmount)) {
      setAmount(newAmount)
      return true
    }
    return false
  }

  return (
    <div className="checkoutcontainer overflow-auto">
      <ProductComponent
        product={product}
        windowWidth={windowWidth}
        onAmountChange={onAmountChange}
      />
      <StripeComponent amount={amount} windowWidth={windowWidth} />
    </div>
  );
}

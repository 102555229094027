type OAuthScopes = 'phone' |
    'email' |
    'profile' |
    'openid' |
    'aws.cognito.signin.user.admin'

type AppConfig = {
    env: 'dev' | 'prod'
    amplify: AmplifyConfig
}

type AmplifyConfig = {
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab'
        identityPoolId: string

        // REQUIRED - Amazon Cognito Region
        // 'XX-XXXX-X'
        region: string

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        // 'XX-XXXX-X'|
        identityPoolRegion?: string

        // OPTIONAL - Amazon Cognito User Pool ID
        // 'XX-XXXX-X_abcd1234',
        userPoolId?: string

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        //  'a1b2c3d4e5f6g7h8i9j0k1l2m3'
        userPoolWebClientId?: string

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        // false
        mandatorySignIn?: boolean

        // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
        // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
        // 'code' is default.
        signUpVerificationMethod?: 'code' | 'link'

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        cookieStorage?: {
            // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            // '.yourdomain.com'
            domain: string
            // OPTIONAL - Cookie path
            //  '/',
            path?: string
            // OPTIONAL - Cookie expiration in days
            // 365,
            expires?: number
            // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            sameSite?: 'strict' | 'lax'
            // OPTIONAL - Cookie secure flag
            // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            // true,
            secure?: boolean
        },

        // OPTIONAL - customized storage object
        storage?: any,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType?: 'USER_SRP_AUTH' | 'USER_PASSWORD_AUTH' | 'CUSTOM_AUTH',

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        clientMetadata?: any //{myCustomKey: 'myCustomValue'},

        // OPTIONAL - Hosted UI configuration
        oauth?: {
            domain: string//'your_cognito_domain',
            scope: OAuthScopes[]
            redirectSignIn: string //'http://localhost:3000/',
            redirectSignOut: string //'http://localhost:3000/',
            responseType: 'code' | 'token' //note that REFRESH token will only be generated when the responseType is code
        },
    },
    API?: {
        endpoints: Array<{
            name: string
            endpoint: string
            region: string
        }>
    }
}


const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

const REDIRECT_URL = `https://${process.env.REACT_APP_UI_HOST}`


export const Config: AppConfig = {
    env: "dev",
    amplify: {
        Auth: {
            mandatorySignIn: false,
            region: process.env.REACT_APP_AUTH_REGION,
            identityPoolId: process.env.REACT_APP_AUTH_IDENTITYPOOLID,
            userPoolId: process.env.REACT_APP_AUTH_USERPOOLID,
            userPoolWebClientId: process.env.REACT_APP_AUTH_APPCLIENTID,
            oauth: {
                domain: process.env.REACT_APP_AUTH_HOST!,
                redirectSignIn: isLocalhost ? "http://localhost:3000" : REDIRECT_URL,
                redirectSignOut: isLocalhost ? "http://localhost:3000" : REDIRECT_URL,
                responseType: "code",
                scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin']
            }
        },
        API: {
            endpoints: [
                {
                    name: "letters",
                    endpoint: `https://${process.env.REACT_APP_API_HOST}/v0/letters`,
                    region: process.env.REACT_APP_AUTH_REGION,
                },
                {
                    name: "words",
                    endpoint: `https://${process.env.REACT_APP_API_HOST}/v0/words`,
                    region: process.env.REACT_APP_AUTH_REGION,
                },
                {
                    name: "payments",
                    endpoint: `https://${process.env.REACT_APP_API_HOST}/v0/payments`,
                    region: process.env.REACT_APP_AUTH_REGION,
                },
            ]
        }
    }
}

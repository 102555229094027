import React, { useEffect, useRef, useState } from "react";
import { Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";

const LETTERS_LENGTH = 7;

interface SevenLettersProps {
  letters?: string; // The current letters to prepopulate the field
  setLetters: React.Dispatch<string>;
  onBlur?: () => void;
}

const SevenLetters: React.FC<SevenLettersProps> = ({
  letters,
  setLetters,
  onBlur
}: SevenLettersProps) => {
  const [value, setValue] = useState<string>(letters ?? "");
  const [validated, setValidated] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(true);
  const [invalidMsg, setInvalidMsg] = useState<string>("");
  const [pattern, setPattern] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    validate(event.target.value);
  };

  const handleBlur = () => {
    // Validate the form and pass the onBlur up
    validate(value)
    setValidated(true);
    if(onBlur) {
      onBlur();
    }
  };

  useEffect(() => {
    inputRef.current?.select()
  }, []);

  const validate = (value: string) => {
    const numUniqueChars = new Set(value).size;

    setLetters(value);

    if (value.length !== LETTERS_LENGTH) {
      setValid(false);
      setInvalidMsg(`You must specify ${LETTERS_LENGTH} characters.`);
      // Setting the pattern to something wrong so that
      // the native html form validation is invalid.
      // Otherwise, the control displays a green checkmark.
      setPattern("DEADBEEF");
    } else if (numUniqueChars !== value.length) {
      setValid(false);
      setInvalidMsg("All characters must be unique.");
      setPattern("DEADBEEF");
    } else {
      setValid(true);
      setInvalidMsg("");
      setPattern(value);
    }
  };

  return (
    <FormGroup>
      <FormLabel>Seven letters that words can use:</FormLabel>
      <FormControl
        ref={inputRef}
        autoFocus
        required
        type="text"
        onKeyDown={(e)=>{if(e.code === 'Enter') {e.preventDefault(); inputRef.current?.blur()}}}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        minLength={LETTERS_LENGTH}
        maxLength={LETTERS_LENGTH}
        pattern={pattern}
        placeholder="Enter 7 unique alpha characters"
        isInvalid={validated && !valid}
        isValid={validated && valid}
      />
      <Form.Control.Feedback type="invalid">{invalidMsg}</Form.Control.Feedback>
    </FormGroup>
  );
};

export default SevenLetters;

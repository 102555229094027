import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import "./sponsor.scss";
import { ProductType } from "./product"

const Sponsor: React.FC = () => {
  const [oneTimeAmount, setOneTimeAmount] = useState(4.0);
  const [recurringAmount, setRecurringAmount] = useState(1.0);
  const [showAlert, setShowAlert] = useState(false);
  const minimumgift = 0.5;
  const navigate = useNavigate();

  const handleCheckout = (type: ProductType, price: number) => {
    if (price < minimumgift) {
      setShowAlert(true);
    }

    // Handle the payment processing here
    navigate(`/sponsorship/checkout?amount=${price * 100}&type=${type}`);
  };

  return (
    <div className="vh-100 vw-100 text-center">
      <div
        hidden={!showAlert}
        className="alert alert-warning alert-dismissible fade show"
        role="alert"
      >
        Minimum gift is <strong>${(0.5).toFixed(2)}</strong> (imposed by our
        credit card processor).
        <button
          type="button"
          className="btn-close"
          onClick={() => setShowAlert(false)}
          aria-label="Close"
        />
      </div>
      <span className="lead m-2 text-center">
        Sponsorships keep the site ad-free and bee-autiful!
      </span>
      <div className="d-flex justify-content-center w-100">
        <div className="d-flex flex-wrap justify-content-center">
          <Card className="m-2 sponsorshipcard">
            <Card.Body>
              <Card.Title>One-Time Sponsorship</Card.Title>
              <Form>
                <Form.Group controlId="formOneTimeAmount">
                  <div className="w-100 text-start">
                    <span>Choose your amount:</span>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <span className="fs-3 me-1">$</span>{" "}
                    <Form.Control
                      type="number"
                      placeholder="Enter amount"
                      value={oneTimeAmount}
                      min="0.00"
                      step="1.00"
                      onChange={(e) => setOneTimeAmount(Number(e.target.value))}
                    />
                  </div>
                  <Form.Text className="text-muted">
                    One-time gift of ${oneTimeAmount.toFixed(2)}
                  </Form.Text>
                </Form.Group>
                <Button
                  className="mt-2"
                  variant="warning"
                  onClick={() =>
                    handleCheckout("onetime", Number(oneTimeAmount.toFixed(2)))
                  }
                >
                  Checkout
                </Button>
              </Form>
            </Card.Body>
          </Card>

          <Card className="m-2 sponsorshipcard">
            <Card.Body>
              <Card.Title>Monthly Sponsorship</Card.Title>
              <Form>
                <Form.Group controlId="formRecurringAmount">
                  <div className="w-100 text-start">
                    <span>Choose your amount:</span>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <span className="fs-3 me-1">$</span>{" "}
                    <Form.Control
                      type="number"
                      placeholder="Enter amount"
                      value={recurringAmount}
                      min="0.00"
                      step="1.00"
                      onChange={(e) =>
                        setRecurringAmount(Number(e.target.value))
                      }
                    />
                  </div>
                  <Form.Text className="text-muted">
                    Monthly gift of ${recurringAmount.toFixed(2)}
                  </Form.Text>
                </Form.Group>
                <Button
                  className="mt-2"
                  variant="warning"
                  onClick={() =>
                    handleCheckout(
                      "recurring",
                      Number(recurringAmount.toFixed(2))
                    )
                  }
                >
                  Checkout
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Sponsor;

import { useEffect, useState } from "react";

type resultTuple<T> = [T, (keys: string) => void];

function useKeyTracker() : resultTuple<string> {
  const [keys, setKeys] = useState("");

  const resetKeys = (keys: string) => {
    setKeys(keys.toLowerCase().replace(/[^a-zA-Z]/g, ""));
  };

  useEffect(() => {
    function handleKeyDown(this: HTMLElement, ev: KeyboardEvent) {
      if (document.body.classList.contains("modal-open")) {
        return
      }
      const alphaChars = /^[a-zA-Z]$/;
      if (ev.key === "Backspace") {
        setKeys(keys.slice(0, keys.length - 1));
      } else if (ev.key === "Escape") {
        setKeys("");
      } else if (ev.key.match(alphaChars)) {
        setKeys(keys + ev.key.toLowerCase());
      }
    }

    document.body.addEventListener("keydown", handleKeyDown);

    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, [keys]);

  return [keys, resetKeys];
}

export default useKeyTracker;

import React, { useState } from "react";
import { Product } from "./product";

interface ProductComponentOptions {
  product: Product | null;
  windowWidth: number;
  onAmountChange: (amount: number) => boolean;
}

const ProductComponent: React.FC<ProductComponentOptions> = ({
  product,
  windowWidth,
  onAmountChange,
}) => {
  const [lastValidAmount, setLastValidAmount] = useState(
    product ? product.price : 400
  );
  return (
    <div
      className={`product text-light bg-secondary ${
        windowWidth <= 1000 ? "p-1" : "p-5"
      }`}
    >
      {product ? (
        <div className="d-flex flex-column align-items-center">
          <h3
            className={`text-center ${windowWidth <= 1000 ? "mb-2" : "mb-5"}`}
          >
            {product.name}
          </h3>
          <div className="input-group input-group-lg">
            <div className="input-group-prepend input-group-lg">
              <span className="input-group-text">$</span>
            </div>
            <input
              id="product-amount"
              type="number"
              min={1}
              className="form-control"
              aria-label="Amount (to the nearest dollar)"
              defaultValue={product.price / 100}
              onChange={(v) => {
                const a = Number(v.currentTarget.value) * 100;
                if (onAmountChange(a)) {
                  setLastValidAmount(a);
                }
              }}
              onBlur={(v) => {
                v.target.value = (lastValidAmount / 100).toFixed(0);
              }}
            />
            <div className="input-group-append input-group-lg">
              <span className="input-group-text">.00</span>
            </div>
          </div>
          <div>
            <div className="img-container">
              <img src="/logo512.png" alt="beehive" draggable={false} />
            </div>
            <p className="text-center">{product.description}</p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProductComponent;

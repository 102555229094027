import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import PaymentForm from "./payment";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!);

interface StripeComponentOptions {
  amount: number;
  windowWidth: number;
}

const StripeComponent: React.FC<StripeComponentOptions> = ({
  amount,
  windowWidth,
}) => {
  return (
    <div className={`stripediv ${windowWidth <= 1000 ? "p-1" : "p-4"}`}>
      <Elements
        stripe={stripePromise}
        options={{
          mode: "payment",
          amount: amount,
          currency: "usd",
        }}
      >
        <div className="stripe-outer-container">
          <div id="checkoutform" className="stripe-inner-container">
            <PaymentForm amount={amount} />
          </div>
        </div>
      </Elements>
    </div>
  );
};

export default StripeComponent;

import Button from "react-bootstrap/Button";
import "./bigbutton.scss";

interface BigButtonProps {
  message: string;
  action: string;
}

function BigButton({ message, action }: BigButtonProps) {
  return (
    <div className="bbcontainer">
      <div className="bbheader text-center">
        <h2>{message}</h2>
      </div>
      <div className="bbcontent">
        <div className="container d-flex flex-column justify-content-center align-items-center">
          <Button
            variant="light"
            href={`${window.location.protocol}//${process.env.REACT_APP_UI_HOST}`}
            className="m-2 bigbutton"
            draggable="false"
          >
            <img src="/logo512.png" alt="beehive" draggable="false"></img>
            <h1 className="">{action}</h1>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default BigButton;

import "@aws-amplify/ui-react/styles.css";
import "./App.scss";

import { Outlet } from "react-router";

import { useEffect } from "react";
import Navbar from "./navbar";

function App() {
  const screenfillerid = "screenfiller";
  function vh100() {
    const elem = document.getElementById(screenfillerid);
    if (elem && elem?.style && elem.style.height !== undefined) {
      elem.style.height = window.innerHeight + "px";
    }
  }

  useEffect(() => {
    // Call vh100 immediately on component mount
    vh100();
    // And then on window resize
    window.addEventListener("resize", vh100);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", vh100);
    };
  }, []); // Emptry array means effect runs once on mount and for cleanup only.

  return (
    <div
      id={screenfillerid}
      className="d-flex flex-column vw-100 overflow-hidden"
    >
      <Navbar></Navbar>
      <div className="app-content">
        <Outlet />
      </div>
    </div>
  );
}

export default App;

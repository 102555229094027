import { bind } from '@react-rxjs/core'
import { Observable, from } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators'

import { API, } from 'aws-amplify'
import Word from './word'
import { createSignal } from '@react-rxjs/utils'

export interface WordsProps {
    letters: string
    lettersLoading: boolean
    filter?: string
    prefix?: string
}

const [loadingWords$, setLoadingWords] = createSignal<boolean>()

export function fetchWordsLoading() {
    const [useLoadingWords] = bind<boolean>(loadingWords$, false)
    return useLoadingWords
}


export default function fetchWords(props$: Observable<WordsProps>) {
    const [useWords] = bind<Word[]>(
        props$.pipe(
            filter((v) => {
                return v.letters.length === 7 && !v.lettersLoading
            }),
            debounceTime(250),
            distinctUntilChanged((p, c) => {
                return (
                    p.letters === c.letters &&
                    p.filter === c.filter &&
                    p.prefix === c.prefix
                )
            }),
            switchMap((props) => from(propsToWords(props).catch((error) => {
                console.error("Failed to get words:", error)
                return []
            })))  // use the 'from' function from rxjs to convert the Promise to an Observable
        ),
        []
    )
    return useWords
}

async function propsToWords(props: WordsProps): Promise<Word[]> {

    try {
        const init = {
            queryStringParameters: props
        }

        setLoadingWords(true)
        const response = await API.get("words", "", init)
        return response
    } catch (error) {
        console.error('Error fetching words', error)
        throw error
    } finally {
        setLoadingWords(false)
    }
}

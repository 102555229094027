import "./hive.scss";

import { useState } from "react";

interface HiveProps {
  letters: string[];
  hiveKeypress: (key: string) => void;
}

const Hive: React.FC<HiveProps> = ({ letters, hiveKeypress }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isTouchEvent, setIsTouchEvent] = useState<boolean>(false);

  const handleMouseDown = (index: number) => {
    if (isTouchEvent) {
      setIsTouchEvent(false);
      return;
    }
    setActiveIndex(index);
    hiveKeypress(letters[index]);
  };

  const handleMouseUp = () => {
    setActiveIndex(null);
  };

  const handleTouchStart = (index: number) => {
    setIsTouchEvent(true);
    setActiveIndex(index);
    hiveKeypress(letters[index]);
  };

  return (
    <div className="hive-container">
      <div className="hive">
        {letters.map((letter, index) => (
          <svg key={index} className="hive-cell" viewBox="0 0 300 260">
            <polygon
              points="75,0 225,0 300,130 225,260 75,260 0,130"
              stroke="white"
              strokeWidth={"10"}
              className={
                index === 0
                  ? index === activeIndex
                    ? "center active"
                    : "center"
                  : index === activeIndex
                  ? "outer active"
                  : "outer"
              }
              onMouseDown={()=>{handleMouseDown(index)}}
              onMouseLeave={handleMouseUp}
              onMouseUp={handleMouseUp}
              onTouchStart={()=>handleTouchStart(index)}
              onTouchEnd={handleMouseUp}
            ></polygon>
            <text
              dominantBaseline={"middle"}
              x="150"
              y="140"
              className="letter"
            >
              {letter.toUpperCase()}
            </text>
          </svg>
        ))}
      </div>
    </div>
  );
};

export default Hive;

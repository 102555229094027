import { createSignal } from "@react-rxjs/utils";
import { useEffect, useState } from "react";
import DivInput from "./div-input";
import Hive from "./hive/hive";
import "./home.scss";
import Settings from "./settings/settings";
import useAppSettings from "./settings/use-appsettings";
import VerticalRange from "./settings/vrange";
import useKeyTracker from "./use-keytracker";
import { useStateLowerCase } from "./util";
import Words from "./words";
import fetchWords, { WordsProps, fetchWordsLoading } from "./words-fetch";
import { Spinner } from "react-bootstrap";
const [props$, setProps] = createSignal<WordsProps>();
const useWords = fetchWords(props$);
const useWordsLoading = fetchWordsLoading();

function Home() {
  const [appSettings, setAppSettings] = useAppSettings();

  const [prefix, setPrefix] = useStateLowerCase("");
  const [filter, setFilter] = useStateLowerCase("");
  const [keys, resetKeys] = useKeyTracker();
  const [mode, setMode] = useState(appSettings.hivemode);
  const words = useWords();
  const wordsLoading = useWordsLoading();

  const setHiveKey = (key: string) => {
    resetKeys(keys + key);
  };

  const clearFilter = () => {
    setFilterMode();
    setFilter("");
    resetKeys("");
  };
  const clearPrefix = () => {
    setPrefixMode();
    setPrefix("");
    resetKeys("");
  };

  // Store default value for obscurity limit.
  const [obscurityLimit, setObscurityLimit] = useState(() => {
    const storedValue = localStorage.getItem("obscurityLimit");
    return storedValue ? Number(storedValue) : 70;
  });
  useEffect(() => {
    localStorage.setItem("obscurityLimit", obscurityLimit.toString());
  }, [obscurityLimit]);

  useEffect(() => {
    setProps({
      letters: appSettings.letters,
      lettersLoading: appSettings.lettersLoading,
      filter: filter,
      prefix: prefix,
    });
  }, [appSettings.letters, appSettings.lettersLoading, prefix, filter]);

  // Handle typing in the filter and prefix fields
  useEffect(() => {
    if (mode !== appSettings.hivemode) {
      const newMode = appSettings.hivemode;
      resetKeys(newMode === "filter" ? filter : prefix);
      setMode(newMode);
    } else {
      switch (appSettings.hivemode) {
        case "filter": {
          setFilter(keys.toString());
          break;
        }
        case "prefix": {
          setPrefix(keys.toString());
          break;
        }
      }
    }
  }, [
    keys,
    appSettings.hivemode,
    setFilter,
    setPrefix,
    mode,
    filter,
    prefix,
    resetKeys,
  ]);

  const setFilterMode = () =>
    setAppSettings({ ...appSettings, hivemode: "filter" });
  const setPrefixMode = () =>
    setAppSettings({ ...appSettings, hivemode: "prefix" });

  return (
    <div className="homecontainer">
      <div className="homecontent">
        {wordsLoading && words.length === 0 ? (
          <Spinner variant="warning" />
        ) : (
          <Words
            letters={appSettings.letters}
            words={words}
            nchars={appSettings.showNChars}
            obscurityLimit={obscurityLimit}
          ></Words>
        )}
      </div>
      <div className="homefooter m-2">
        <div className="settingshivecontainer">
          <div className="appsettings">
            <Settings
              settings={appSettings}
              setSettings={setAppSettings}
            ></Settings>
            <VerticalRange
              initialValue={obscurityLimit}
              onChange={setObscurityLimit}
            ></VerticalRange>
          </div>
          {appSettings.lettersLoading ? (
            <div className="spinner-container">
              <Spinner variant="warning" className="spinner" />
            </div>
          ) : (
            <Hive
              letters={Array.from(appSettings.letters)}
              hiveKeypress={setHiveKey}
            />
          )}
        </div>
        <div onClick={setFilterMode} onTouchStart={setFilterMode}>
          <DivInput
            hasFocus={appSettings.hivemode === "filter"}
            title="Filter"
            value={filter}
            xclicked={clearFilter}
          ></DivInput>
        </div>
        <div onClick={setPrefixMode} onTouchStart={setPrefixMode}>
          <DivInput
            hasFocus={appSettings.hivemode === "prefix"}
            title="Prefix"
            value={prefix}
            xclicked={clearPrefix}
          ></DivInput>
        </div>
      </div>
    </div>
  );
}

export default Home;

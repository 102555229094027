import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BigButton from "../bigbutton";

export default function ThankYou() {
  const nav = useNavigate();
  useEffect(() => {
    nav(`${window.location.pathname}`, { replace: true });
  }, [nav]);
  return (
    <BigButton
      message="Thank you for your sponsorship!"
      action="Bzzz, Bzzz!"
    ></BigButton>
  );
}

import "./cursor.scss";

import React from "react";

interface CursorInputProps {
  hasFocus: boolean;
}

const Cursor: React.FC<CursorInputProps> = (props: CursorInputProps) => {
  let span = props.hasFocus ? (
    <span className="h2 blinking-cursor text-warning mb-1">|</span>
  ) : (
    ""
  );
  return <div className="d-flex align-items-center">{span}</div>;
};

export default Cursor;

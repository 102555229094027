import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Gear } from "react-bootstrap-icons";
import { useEffect, useRef, useState } from "react";
import SevenLetters from "./seven-letters"
import { useStateLowerCase } from "../util";
import { Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { AppSettings } from "./use-appsettings";

export interface AppSettingsProps {
  settings: AppSettings;
  setSettings: React.Dispatch<React.SetStateAction<AppSettings>>;
}

function Settings({ settings, setSettings }: AppSettingsProps) {
  const [show, setShow] = useState(false);
  const [letters, setLetters] = useStateLowerCase(settings.letters);
  const [showNChars, setShowNChars] = useState(settings.showNChars);

  const [saveDisabled, setSaveDisabled] = useState(true);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  const handleShowNCharsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(parseInt(e.target.value, 10), 0);
    setShowNChars(value);
  };

  const handleBlur = () => {
    if (letters.length === 7) {
      saveButtonRef?.current?.focus();
    } else {
      cancelButtonRef?.current?.focus();
    }
  };

  const handleOpen = () => {
    setShow(true);
  };
  const handleCancel = () => setShow(false);
  const handleSave = () => {
    setSettings({ ...settings, letters: letters, showNChars: showNChars });
    setShow(false);
  };

  // Keep the default value for field in sync with settings
  useEffect(()=>{
    setLetters(settings.letters)
  }, [settings.letters, setLetters])

  useEffect(() => {
    if (letters.length === 7) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [letters]);

  return (
    <div>
      <Button
        variant="outline-light text-dark"
        onClick={handleOpen}
      >
        <Gear className="h2" />
      </Button>
      <Modal show={show} onHide={handleCancel}>
        <Modal.Header closeButton onClick={handleCancel}>
          <Modal.Title>Settings</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            onSubmit={(e) => {e.preventDefault();}}
          >
            <SevenLetters
              letters={letters}
              setLetters={setLetters}
              onBlur={handleBlur}
            ></SevenLetters>
            <FormGroup>
              <FormLabel>Show the first N characters:</FormLabel>
              <FormControl
                type="number"
                value={showNChars}
                onChange={handleShowNCharsChange}
                min="0"
              ></FormControl>
            </FormGroup>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            ref={cancelButtonRef}
            variant="secondary"
            onClick={handleCancel}
          >
            Close
          </Button>
          <Button
            ref={saveButtonRef}
            variant="primary"
            onClick={handleSave}
            disabled={saveDisabled}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Settings;

import { useCallback, useState } from "react";

type SetStateTuple<T> = [T, React.Dispatch<T>];

export function useStateLowerCase(initialState: string): SetStateTuple<string> {
  const [state, setState] = useState<string>(initialState);

  const setLowerCase = useCallback((newValue: React.SetStateAction<string>) => {
    setState(newValue.toString().toLowerCase());
  }, []);

  return [state, setLowerCase];
}

import "./word-elem.scss";

import React from "react";

import Word from "./word";

interface WordElemProps {
  word: Word;
  ispangram?: boolean;
  nchars?:number;
}

function hideAfterN(str: string, n: number): string {
  if (n >= str.length || n<0) {
    return str;
  }
  const firstNChars = str.slice(0, n);
  const asterisks = "*".repeat(str.length-n);
  return firstNChars + asterisks;
}

const WordElem: React.FC<WordElemProps> = (props: WordElemProps) => {
  return (
    <span className={`border rounded text-left ps-1`}>
      <a tabIndex={-1} className={"text-dark word-font"} href={props.word.url}
      target="_blank" rel="noopener noreferrer">
        {props.ispangram ? (
          <>
            <b>{hideAfterN(props.word.word, props.nchars ?? -1)}</b>
          </>
        ) : (
          hideAfterN(props.word.word, props.nchars ?? -1)
        )}
      </a>
    </span>
  );
};

export default WordElem;

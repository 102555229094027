import "./navbar.scss";

import { FunctionComponent } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";

import { Button, Dropdown } from "react-bootstrap";
import { CurrencyDollar, List } from "react-bootstrap-icons";

type NavbarProps = {
  className?: string;
  noUser?: boolean;
};

const Navbar: FunctionComponent<NavbarProps> = ({ className, noUser }) => {
  const navigate = useNavigate();
  return (
    <Nav bg="warning" variant="light" className={className + " noselect"}>
      <Container fluid>
        <Link to="/" className="nolink">
          <Nav.Brand>LexiHive</Nav.Brand>
        </Link>
        <Link to="/sponsorship/checkout?amount=400&type=onetime">
          <Button
            variant="warning"
            className="d-inline-flex justify-content-center align-items-center"
          >
            <CurrencyDollar className="fs-5" />
            <span className="fs-6">Tips Appreciated</span>
          </Button>
        </Link>
        <Dropdown>
          <Dropdown.Toggle
            as={Button}
            variant="warning"
            id="user-dropdown"
            title={"Menu"}
          >
            <List />
          </Dropdown.Toggle>
          <Dropdown.Menu align={"end"}>
            <Dropdown.Item
              onClick={() => {
                navigate("/support");
              }}
            >
              Support
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Container>
    </Nav>
  );
};

export default Navbar;
